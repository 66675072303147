import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { List, ListProps } from "./list";
import { Category, useWebgate } from "@xvia/webgate-connect";
import { getServerData } from "../../util/get-server-data";
import { Catalog, search } from "../../apis/search-catalog";
import { useHistory, useParams } from "react-router-dom";
import { getCategories } from "../../apis/categories";
import { IDepartment, getDepartments } from "../../apis/department";
import { getTarget, getTopCatalog } from "../../apis/relevant-catalog";

const striptags = require("striptags");

export const ListContainer: FC = () => {
  const { portalConnect } = useWebgate();
  let history = useHistory();

  let { category, query, orgao } =
    useParams<{ category: string; query: string; orgao: string }>();

  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const [departmentList, setDepartmentList] = useState<IDepartment[]>([]);
  const [catalogList, setCatalogList] = useState<any[]>([]);
  const [searchTextFilter, setSearchTextFilter] = useState<string>(query);
  const [searchCategoryFilter, setSearchCategoryFilter] = useState<string>("");
  const [categorySlug, setCategorySlug] = useState<string>(category);
  const [page, setPage] = useState<number>(0);
  const [resultTotal, setResultTotal] = useState<number>();
  const [isMounted, setIsMounted] = useState(false);

  const currentPage = useMemo(() => page + 1, [page]);

  const openLink = useCallback(
    (link) => {
      if (link.indexOf("/app/") !== -1) {
        portalConnect?.redirectTo(link);
      } else {
        window.open(link, "_blank");
      }
    },
    [portalConnect]
  );

  const onClickCard = useCallback(
    (categorySlug, slug) => {
      history.push(`/${categorySlug}/${slug}`);
    },
    [history]
  );

  const onClickSearchCategory = useCallback(
    (categorySlug: string) => {
      history.push(`/list/${categorySlug}/${searchTextFilter || ""}`);
      setCategorySlug(categorySlug);
    },
    [history, searchTextFilter]
  );

  const onClickClearSearchCategory = useCallback(() => {
    setCategorySlug("");
    if (searchTextFilter) return history.push(`/search/${searchTextFilter}`);

    history.push(`/list/`);
  }, [history, searchTextFilter]);

  const onClickSearch = useCallback(() => {
    if (categorySlug)
      return history.push(`/list/${categorySlug}/${searchTextFilter || ""}`);

    history.push(`/search/${searchTextFilter ?? ""}`);
  }, [searchTextFilter, history, categorySlug]);

  const onClickClearFilter = useCallback(() => {
    setSearchTextFilter("");
    if (categorySlug) return history.push(`/list/${categorySlug}`);

    history.push(`/list/`);
  }, [categorySlug, history]);

  const onDepartmentClick = useCallback(
    (departamentSlug: string) => {
      history.push(`/list/orgao/${departamentSlug}`);
    },
    [history]
  );

  const onPaginate = useCallback((id: number) => {
    setPage(id - 1);
    if (typeof window !== "undefined" && window.scrollY !== 0) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (category === "Cidadão" || query === "Cidadão") {
      getTarget(undefined, category).then((res) => {
        setCatalogList(res);
        setResultTotal(res[0]?.resultTotal);
      });
      return;
    }
    if (category === "mais-acessados" || query === "mais-acessados") {
      getTopCatalog(undefined).then((res) => {
        setCatalogList(res);
        setResultTotal(10);
      });
      return;
    }
    if (category === "acesso-rapido" || query === "acesso-rapido") {
      search(undefined, undefined, category, orgao, page * 10, true).then(
        (res) => {
          setCatalogList(res);
          setResultTotal(res[0]?.resultTotal);
        }
      );
      return;
    }
    search(undefined, query, category, orgao, page * 10)
      .then((res) => {
        setCatalogList(res);
        setResultTotal(res[0]?.resultTotal);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [query, category, orgao, page]);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);

      const parsedCategoryData = getServerData("categoryList", []);
      const parsedDepartmentData = getServerData("departmentList", []);
      const parsedCatalogData = getServerData("catalogList", []);

      if (parsedCategoryData.length === 0) {
        getCategories()
          .then(setCategoryList)
          .catch((err) => {
            console.error("Error fetching category list:", err);
          });
      } else {
        setCategoryList(parsedCategoryData);
      }

      if (parsedDepartmentData.length === 0) {
        getDepartments()
          .then(setDepartmentList)
          .catch((err) => {
            console.error("Error fetching department list:", err);
          });
      } else {
        setDepartmentList(parsedDepartmentData);
      }

      setCatalogList(parsedCatalogData);
    }
  }, [isMounted]);

  const newsProps: ListProps = {
    list: catalogList?.map((catalog) => {
      return {
        ...catalog,
        description: striptags(catalog.description),
      } as Catalog;
    }),
    category: category,
    categoryFilter: categorySlug,
    onClick: onClickCard,
    onClickOnlineService: openLink,
    searchTextFilter,
    prefix: query ? "/ Palavra-chave:" : "",
    searchText: query,
    onSetSearchCategoryFilter: setSearchCategoryFilter,
    onClickSearchCategory,
    searchCategoryFilter,
    onClickSearch,
    onSetSearchValue: setSearchTextFilter,
    categoryList,
    onCategorySelect: setCategorySlug,
    categoryTitle: categoryList?.filter((i) => {
      return i.slug === category;
    })[0]?.name,
    departmentList,
    onDepartmentClick,
    orgaoSlug: orgao,
    orgaoTitle: departmentList?.filter((i) => {
      return i.slug === orgao;
    })[0]?.shortName,
    onPaginate,
    resultTotal,
    currentPage,
    startLink: <a href="/">Início</a>,
    onClickClearFilter,
    onClickClearSearchCategory,
  };

  return <List {...newsProps} />;
};
